import OcmMessenger from "ocm_mercurius_messenger";
import { messengerPublicationTypes } from "@ocm/services/services.constants";

const addToast = (data) => {
	OcmMessenger.publish(messengerPublicationTypes.ADD_EMARSYS_TOAST, data);
};
const addPhoneNumberModal = () => {
	OcmMessenger.publish(messengerPublicationTypes.ADD_EMARSYS_PHONENUMBER_MODAL);
};

export { addToast, addPhoneNumberModal };
