import OcmMessenger from "ocm_mercurius_messenger";
import { messengerPublicationTypes } from "@ocm/services/services.constants.js";

function init() {
	OcmMessenger.subscribe(messengerPublicationTypes.UPDATED_MINI_CART, updateMiniCartTotalItemCount);
}

function updateMiniCartTotalItemCount({ data }) {
	const $miniCartIcon = $("#miniCartIcon");

	if ($miniCartIcon.length < 1) {
		return;
	}

	const $miniCartCounterBadge = $(".js-miniCartCounter-badge");

	if (typeof data === "number") {
		$miniCartCounterBadge.text(data);
	} else {
		const url = `/rest/mini-cart/count/`;
		$.ajax({
			url,
			global: false,
			type: "GET",
			dataType: "json",
		})
			.done((data) => {
				$miniCartCounterBadge.text(data);
			})
			.fail((jqXHR, textStatus, errorThrown) => {
				console.error(errorThrown);
			});
	}
}

export { init, updateMiniCartTotalItemCount };
