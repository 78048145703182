import { computed, reactive } from "vue";

export default function () {
	const useMatchMedia = (mediaQuery: string) => {
		const match = window.matchMedia(mediaQuery);
		const ref = reactive({ value: match.matches });

		if ("addEventListener" in match) match.addEventListener("change", () => (ref.value = match.matches));
		else if ("addListener" in match)
			(match as MediaQueryList).addListener(() => (ref.value = (match as MediaQueryList).matches));
		return ref;
	};

	const isLt1024 = useMatchMedia("not all and (min-width: 1024px)");

	const isMobile = computed(() => isLt1024.value);

	return {
		isMobile,
	};
}
