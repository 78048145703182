export async function fetchWithCSRFToken(url: string, options: RequestInit) {
	const completeOptions: RequestInit = {
		...options,
		headers: {
			csrftoken: window.ocm.config.CSRFToken || "",
			...options.headers,
		},
	};

	let response;
	try {
		response = await fetch(url, completeOptions);
	} catch (e) {
		console.error(e);
		return { httpStatus: 503 };
	}

	const csrfToken = response.headers.get("csrftoken");
	if (csrfToken) window.ocm.config.CSRFToken = csrfToken;

	const responseData = { httpStatus: response.status };
	try {
		const returnJson = await response.json();
		Object.assign(responseData, returnJson);
	} catch (e) {
		console.error(e);
	}

	return responseData;
}
