import { reactive } from "vue";

// todo TUB-18950 after AB-Test: remove abTestIsTestPage, abTestFirstStep
export const store = reactive<{
	isLogin: boolean;
	isModal: boolean;
	isClassic: boolean;
	isReauth: boolean;
	isLastStepPage: boolean;
	isLandingPage: boolean;
	username: string;
	isSocialLogin: boolean;
	globalErrorMessages: string[];
	httpErrorStatusCode: number | null;

	abTestIsTestPage: boolean;
	abTestFirstStep: boolean;
}>({
	isLogin: true,
	isModal: true,
	isClassic: false,
	isReauth: false,
	isLastStepPage: false,
	isLandingPage: false,
	username: "",
	isSocialLogin: false,
	globalErrorMessages: [],
	httpErrorStatusCode: null,

	abTestIsTestPage: false,
	abTestFirstStep: false,
});
