import appendLandingPageLayer from "@JS/shop/10-login-component/loginLandingpage";
import OcmMessenger from "ocm_mercurius_messenger";
import { messengerPublicationTypes } from "@ocm/services/services.constants.js";

const _disabledElements = [
	".c-header__nav",
	".js-left-trigger",
	".c-main-content",
	".c-teaser-area a[onclick]",
	".c-secondary-nav-mobile a[onclick]",
];
/** @type {string|null} */
let _selectedLayerName;
let _pageIsOpenPage = false;

const init = function () {
	const isLandingPage = $("body").not(".t-classic").hasClass("pageType-LandingPage");
	const $commonlayer = $("#master-common-layer");
	_selectedLayerName = $commonlayer.data("layerselected") || null;
	if (_selectedLayerName) {
		// Landing Page Login
		if (isLandingPage) {
			appendLandingPageLayer(_selectedLayerName, $commonlayer);
		}
		// LoginLayer 2020
		else if (["Registration", "LoginRegistration"].includes(_selectedLayerName)) {
			_handleLoginLayer2020();
		}
		// Welcome Layer (Don’t remove this until refactored!)
		else {
			_openPopup();
		}
	}
};

const _handleLoginLayer2020 = function () {
	// Login Interrupt; s. PLS, Inspiration Page, Inspiration Detail Page
	if (!document.getElementById("js-login-interrupt")) return;

	_disabledElements.forEach((el) => {
		$(el).off("mousedown", mouseDownElementsHandler).on("mousedown", mouseDownElementsHandler);
		$(el).off("click", clickElementsHandler).removeAttr("onclick").on("click", clickElementsHandler);
	});
};

/**
 * @param {Event} e
 */
const clickElementsHandler = (e) => {
	// TUB-20712: Quick fix to block Login Layer on Brand Overview Page
	if (_pageIsOpenPage) return;
	e.stopPropagation();
	e.preventDefault();
	_openPopup(true);
};

const mouseDownElementsHandler = () => {
	// TUB-20712: Quick fix to block Login Layer on Brand Overview Page
	// Check if page is open page BEFORE route change !!!!
	_pageIsOpenPage = window.location.pathname.split("/").includes("marken");
};

const _openPopup = function (isClosable = false) {
	// TUB-20712: Quick fix to block Login Layer on Brand Overview Page
	if (_pageIsOpenPage) return;
	OcmMessenger.publish(messengerPublicationTypes.REQUEST_LOGIN, { isClosable });
};

const commonPopupTrigger = {
	init,
};

export default commonPopupTrigger;
