import { Ref } from "vue";
import { ValidationOptions } from "../types/InputValidation.type";

export const useFileValidation = (inputRef: Ref<HTMLInputElement>, options?: ValidationOptions) => {
	const getSelectedFile = () => inputRef.value?.files?.[0] || null;

	const isFileSizeError = () => {
		if (!options?.maxFileSizeMb) return false;
		return (getSelectedFile()?.size || 0) > options.maxFileSizeMb * 1000 * 1000;
	};

	const isFileTypeError = () => {
		if (!inputRef.value.accept || !getSelectedFile()?.type) return false;
		return !inputRef.value.accept.split(",").includes(getSelectedFile()?.type || "");
	};

	const getFormattedAcceptString = () =>
		inputRef.value?.accept
			.split(",")
			.map((format) => format.split("/").pop()?.toUpperCase())
			.join(", ") || "";

	return { isFileSizeError, isFileTypeError, getFormattedAcceptString };
};
