/**
 * preview
 *
 * 1) Preview Country Menü on top
 * 2) Preview on Product-Detail Page
 */
//Dom
let $previewFlyout;
let $productDetail;

function init() {
	$previewFlyout = $(".js-preview-flyout");

	if (!$previewFlyout.length) {
		return;
	}

	$productDetail = $(".js-product-detail");

	if ($previewFlyout.length && $productDetail.length) {
		provideCountrySwitchLinks();
	}
}

function provideCountrySwitchLinks() {
	//todo: TUB-7920 muss das noch so sein ?
	const dede = document.getElementById("js-de-de-link");
	const chde = document.getElementById("js-ch-de-link");
	const atde = document.getElementById("js-at-de-link");

	const lengthInclPreview = document.URL.lastIndexOf("preview");
	const concatString = document.URL.substring(lengthInclPreview);

	dede.setAttribute("href", dede.href.replace("preview/p-search/?q=", concatString));
	chde.setAttribute("href", chde.href.replace("preview/p-search/?q=", concatString));
	atde.setAttribute("href", atde.href.replace("preview/p-search/?q=", concatString));
}

export { init };
