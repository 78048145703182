export async function loadScript(url: string) {
	return new Promise((resolve, reject) => {
		const scriptElement = document.createElement("script");
		scriptElement.setAttribute("type", "text/javascript");
		scriptElement.src = url;
		scriptElement.onload = function () {
			resolve(true);
			scriptElement.parentNode?.removeChild(scriptElement);
		};
		scriptElement.onerror = function () {
			reject("Fehler beim Laden des Scriptes");
			scriptElement.parentNode?.removeChild(scriptElement);
		};
		document.body.appendChild(scriptElement);
	});
}

export function isInAppBrowserWithLimitedTabs() {
	const lowerCasedUA = window.navigator.userAgent.toLowerCase().trim();
	const limitedTabBrowserIndicators = [
		"instagram",
		"fbav", // Facebook
		"snapchat",
		"pinterest",
		"twitter",
	];

	return limitedTabBrowserIndicators.some((indicator) => lowerCasedUA.includes(indicator));
}
type Version = { major: number; minor: number; patch: number };
export function isIOSVersionAtMost(version: Version) {
	const userAgent = window.navigator.userAgent;
	const iosRegex = /OS (\d+)_(\d+)_?(\d+)?/;

	const match = iosRegex.exec(userAgent);
	if (!match) {
		return false;
	}

	const [, major, minor, patch] = match.map(Number);
	const currentVersion = { major, minor, patch };

	if (currentVersion.major < version.major) {
		return true;
	}

	if (currentVersion.major === version.major && currentVersion.minor < version.minor) {
		return true;
	}

	if (
		currentVersion.major === version.major &&
		currentVersion.minor === version.minor &&
		currentVersion.patch <= version.patch
	) {
		return true;
	}

	return false;
}

export function isIOS() {
	return window.navigator.userAgent.match(/iPhone|iPad|iPod/i);
}
