import { Ref, ref, inject, computed, watch, nextTick } from "vue";
import { ValidationOptions } from "../types/InputValidation.type";
import { SharedHTMLInputElement, SharedHTMLSelectElement, SharedHTMLTextAreaElement } from "../types/SharedInput.type";
import { useFileValidation } from "./use-file-validation";

export default function useValidationText(
	elementRef: Ref<SharedHTMLTextAreaElement | SharedHTMLSelectElement | SharedHTMLInputElement | null>,
	displayName: string,
	options?: ValidationOptions,
	externalErrorMessage?: Ref<string | undefined>,
	requiredErrorMessage?: string
) {
	const { isFileSizeError, isFileTypeError, getFormattedAcceptString } = useFileValidation(
		elementRef as Ref<HTMLInputElement>,
		options
	);

	const numberOfSubmits: Ref<number> = inject("numberOfSubmits", ref(0));
	const formValidationResetControl: Ref<number> = inject("formValidationResetControl", ref(0));
	const hasFormWrapper: boolean = inject("hasFormWrapper", false);
	const updateFormValidity: () => boolean | void = inject("updateFormValidity", () => {});

	const requiredError = ref(false);
	const patternError = ref(false);
	const typeError = ref(false);
	const fileTypeError = ref(false);
	const fileSizeError = ref(false);
	const rangeOverflowError = ref(false);
	const rangeUnderflowError = ref(false);
	const tooLongError = ref(false);
	const tooShortError = ref(false);
	const badInputError = ref(false);

	const isLiveValidation = ref(false);
	const isValidationVisible = computed(() => {
		const hasExternalErrorMessage = !!externalErrorMessage?.value;
		const hasInternalErrorMessage = !!errorMessage.value;
		const wasSubmitted = numberOfSubmits.value > 0;
		const internalValidationActive = isLiveValidation.value || wasSubmitted;

		return hasExternalErrorMessage || (hasInternalErrorMessage && internalValidationActive);
	});

	// // Helfer für initialLiveValidation, damit bei Validation Reset (formValidationResetControl) die Validierung nicht kurz "aufblitzt"
	let initialLiveValidationTimeout = 0;

	const updateValidateStates = () => {
		if (!elementRef.value) return;

		// patternMissmatch hat höhere Priorität wie typeMismatch (z.B. wichtig bei E-Mail-Pattern)
		const hasPattern = elementRef.value?.getAttribute("pattern");

		requiredError.value = elementRef.value.validity.valueMissing;
		rangeOverflowError.value = elementRef.value.validity.rangeOverflow;
		rangeUnderflowError.value = elementRef.value.validity.rangeUnderflow;
		tooLongError.value = elementRef.value.validity.tooLong;
		tooShortError.value = elementRef.value.validity.tooShort;
		badInputError.value = elementRef.value.validity.badInput;
		patternError.value = elementRef.value.validity.patternMismatch;
		typeError.value = hasPattern ? false : elementRef.value.validity.typeMismatch;
		fileTypeError.value = isFileTypeError();
		fileSizeError.value = isFileSizeError();
	};

	// eslint-disable-next-line complexity
	const errorMessage = computed((): string | undefined => {
		if (externalErrorMessage?.value) return externalErrorMessage?.value;
		if (requiredError.value) return requiredErrorMessage || `${displayName} ist erforderlich`;
		if (patternError.value) return options?.patternErrorMessage || `${displayName} ist ungültig`;
		if (typeError.value) return `${displayName} ist ungültig`;
		if (fileTypeError.value) return `Dateiformat nicht erlaubt. Erlaubt sind ${getFormattedAcceptString()}`;
		if (fileSizeError.value) return `Die File ist zu groß. Erlaubt sind bis zu ${options?.maxFileSizeMb} MB`;
		if (rangeOverflowError.value)
			return elementRef?.value?.type === "date"
				? `${displayName} liegt nicht weit genug zurück`
				: `${displayName} ist zu groß`;
		if (rangeUnderflowError.value)
			return elementRef?.value?.type === "date"
				? `${displayName} ist zu weit in der Vergangenheit`
				: `${displayName} ist zu klein`;
		if (tooLongError.value) return `${displayName} ist zu lang`;
		if (tooShortError.value) return `${displayName} ist zu kurz`;
		if (badInputError.value) return `${displayName} ist ungültig`;

		return undefined;
	});

	// Update errorMessage on the input
	watch(
		() => errorMessage.value,
		() => {
			if (!elementRef.value) return;
			elementRef.value.errorMessage = errorMessage.value;
			const newValidity = errorMessage.value || "";
			elementRef.value.setCustomValidity(newValidity);
		}
	);

	watch(
		() => numberOfSubmits.value,
		(isSubmitTriggered) => {
			if (isSubmitTriggered) updateValidateStates();
		}
	);

	watch(
		() => formValidationResetControl.value,
		() => {
			window.clearTimeout(initialLiveValidationTimeout);
			initialLiveValidationTimeout = 0;
			isLiveValidation.value = false;
		}
	);

	watch(
		() => isValidationVisible.value,
		() => {
			nextTick(updateFormValidity); // next tick to before update the computed error message
		}
	);

	const handleInput = () => nextTick(updateValidateStates);

	const handleChange = (startLiveValidation?: boolean) => {
		updateValidateStates();
		if ((startLiveValidation || !hasFormWrapper) && errorMessage.value) {
			doLiveValidation();
		}
	};

	const handleBlur = handleChange;

	const doLiveValidation = () => {
		if (!initialLiveValidationTimeout && !isLiveValidation.value) {
			initialLiveValidationTimeout = window.setTimeout(() => {
				isLiveValidation.value = true;
			}, 180);
		}
	};

	return {
		errorMessage,
		handleChange,
		handleBlur,
		handleInput,
		isValidationVisible,
	};
}
