/**
 * inspirationOverview
 *
 * Inspiration Homepage
 *
 */

import * as resize from "@sharedJS/resize";

// DOM class/id-names
const copyText = ".js-inspiration-home-copy";

function init() {
	resize.addListener(inspirationOverview);
	setCopyTextCrop();
}

function updateResize() {
	setCopyTextCrop();
}

function setCopyTextCrop() {
	$(copyText).each(function () {
		const $this = $(this);
		if (this.scrollHeight > $this.height()) {
			$this.addClass("isCropped");
		} else {
			$this.removeClass("isCropped");
		}
	});
}

const inspirationOverview = {
	init,
	updateResize,
};

export default inspirationOverview;
