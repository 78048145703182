/**
 * Created by ajla custic on 23.07.2018.
 */

let user_clicked_button = false;

function init() {
	$("body").on("click", ".js-click-once", (e) => {
		if (user_clicked_button) {
			e.preventDefault();
		} else {
			user_clicked_button = true;
		}
	});
}

export { init };
