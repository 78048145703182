/**
 * Wishlist Count
 */

import * as utils from "@sharedJS/utils";
import OcmMessenger from "ocm_mercurius_messenger";
import { messengerPublicationTypes } from "@ocm/services/services.constants.js";

function init() {
	OcmMessenger.subscribe(messengerPublicationTypes.REMOVED_FROM_WISHLIST, wishlistChanged); // published by wishlist
	OcmMessenger.subscribe(messengerPublicationTypes.ADDED_TO_WISHLIST, wishlistChanged); // published by cart
	OcmMessenger.subscribe(messengerPublicationTypes.UPDATE_MULTIPLE_WISHLIST, updateWishlist); // published by plp and pdp

	// Make sure Wishlist count is always up to date (browser back / cache-control)
	requestWishlistItemCount();
}

function requestWishlistItemCount() {
	// does only exist if user's auth level >= 500
	if (!document.querySelector(".js-wishlistCounter-badge")) return;

	const url = _buildUrl("/rest/wishlist/count/");
	try {
		const ajaxRequest = new XMLHttpRequest();
		ajaxRequest.addEventListener("load", onComplete);
		ajaxRequest.open("GET", url);
		ajaxRequest.send();
	} catch (error) {
		console.error(error);
	}
}

function _buildUrl(url) {
	const requestUrl = utils.getUrl(url);
	const requestParams = new URLSearchParams({ _: new Date().getTime() });
	return new URL(`${requestUrl}?${requestParams}`).href;
}

function onComplete() {
	try {
		const responseData = JSON.parse(this.responseText);
		if (Number.isInteger(responseData)) writeItemCount(responseData);
	} catch (error) {
		console.error(error);
	}
}

function updateWishlist(publication) {
	writeItemCount(publication.data.stylesOnWishlistWithSizeCode.length);
}

function wishlistChanged(publication) {
	writeItemCount(publication.data.data.count); // todo: wow
}

function writeItemCount(counter) {
	const wishlistCounterBadges = document.querySelectorAll(".js-wishlistCounter-badge");
	if (wishlistCounterBadges.length === 0) return;
	[...wishlistCounterBadges].forEach((badge) => (badge.textContent = counter));
}

export { init };
