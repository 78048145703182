import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import LoginPage from "./pages/LoginPage.vue";
import ReAuthPage from "./pages/ReAuthPage.vue";
import RegisterLastStepPage from "./pages/RegisterLastStepPage.vue";
import { defaultLocale, fallbackLocale } from "@ocm/services/services.i18n";
import de from "./i18n/de.json";
import en from "./i18n/en.json";
import { store } from "@/state/store";

import { setWebpackPublicPath } from "../../shared/js/webpack-utils";
setWebpackPublicPath();

const wrapperElement: HTMLElement | null | undefined = document
	.getElementById("mf-login")
	?.closest("[data-mfloginwrapper]");
const isModal: boolean = wrapperElement?.dataset.ismodal === "true" ?? false;
const isClassic: boolean = wrapperElement?.dataset.isclassic === "true" ?? false;
const isLandingPage: boolean = wrapperElement?.dataset.islandingpage === "true" ?? false;
const username: string = wrapperElement?.dataset.username || "";
const isReAuth: boolean = wrapperElement?.dataset.isreauth === "true" ?? false;
const isLastStepPage: boolean = wrapperElement?.dataset.islaststeppage === "true" ?? false;

store.isModal = isModal;
store.isClassic = isClassic;
store.isReauth = isReAuth;
store.isLastStepPage = isLastStepPage;
store.isLandingPage = isLandingPage;
store.username = username;

const i18n = createI18n({
	locale: defaultLocale,
	fallbackLocale,
	messages: { de, en },
	legacy: false,
});
const $t = i18n.global.t;

function renderLoginForm() {
	const app = createApp(renderPage());

	app.use(i18n);
	app.mount("#mf-login");
}

function renderPage() {
	if (isReAuth) return ReAuthPage;
	else if (isLastStepPage) return RegisterLastStepPage;
	return LoginPage;
}

// wait for DOM Loaded
if (document.readyState === "loading") {
	document.addEventListener("DOMContentLoaded", renderLoginForm);
} else {
	renderLoginForm();
}

export { $t };
