// TODO TUB-10133 Clean-up

import * as resize from "@sharedJS/resize";

let $imageWrapper;
const minImageHeight = 700; // min-height to show complete Login-Box

function init() {
	$imageWrapper = $(".js-bg-image-wrapper");

	// invokes updateResize() on window.resize
	resize.addListener(landingPage);

	_updateBackground();
}

function _updateBackground() {
	_updateImageWidth();
	_updateImageHeight();
}

function _updateImageWidth() {
	const $image = $imageWrapper.find(".js-bg-image");
	const windowWidth = $(window).width();

	$image.attr("src", $image.attr("src")).load(function () {
		const imageWidth = this.width;
		const imageLeft = (imageWidth - windowWidth) / 2;
		if (windowWidth < imageWidth) {
			$imageWrapper.find(".js-bg-image").css("left", -imageLeft);
		}
	});
}

function _updateImageHeight() {
	const windowHeight = $(window).height();
	const headerHeight = $(".js-header").height();
	const footerHeight = $("#js-footer").height();
	let imagewrapperHeight = windowHeight - headerHeight - footerHeight;
	imagewrapperHeight = imagewrapperHeight < minImageHeight ? minImageHeight : imagewrapperHeight;
	$imageWrapper.css("height", imagewrapperHeight);
}

// necessary Method for ocm.resize
function updateResize(breakpoints) {
	if (breakpoints["l_up"].isScreenSize) {
		_updateBackground();
	} else {
		$imageWrapper.find(".js-bg-image").removeAttr("style");
		$imageWrapper.removeAttr("style");
	}
}

const landingPage = {
	init,
	updateResize,
};

export default landingPage;
