import { waitForEpoqInit } from "@ocm/services/services.epoq";

function init() {
	if (!document.getElementById("js-epoqSPA") && !document.body.classList.contains("page-multiStepCheckoutSummaryPage"))
		_epoqForeignInit();
}

async function _epoqForeignInit() {
	try {
		await waitForEpoqInit();
		console.log("%c%s", "color:#fff;background-color:#35495e;", "Global: EpoqNS.epoqForeignInit");
		const stateUrl = window.location.href;
		window.EpoqNS.epoqForeignInit({ stateUrl });
	} catch (error) {
		console.error("error loading epoq");
	}
}

export { init };
