export function keepFooterOnBottom() {
	const wrapper: HTMLElement | null = document.querySelector(".js-offcanvas-innerwrapper");
	const footer = document.querySelector("#js-footer") as HTMLElement;
	if (!wrapper || !footer) return;

	wrapper.style.position = "relative";
	wrapper.style.minHeight = "100vh";

	footer.style.position = "absolute";
	footer.style.bottom = "0";
	footer.style.width = "100%";

	let footerHeight = 0;
	const setWrapperPaddingBottom = () => {
		if (footerHeight !== footer.offsetHeight) {
			footerHeight = footer.offsetHeight;
			wrapper.style.paddingBottom = `${footerHeight}px`;
		}
	};
	document.querySelector(".c-main-content")?.setAttribute("style", "min-height: unset;");

	setWrapperPaddingBottom();
	window.addEventListener("resize", setWrapperPaddingBottom);
}
