// TODO TUB-10133 Clean-up
// import breakpoints from "@sharedJS/breakpoints";
import * as tooltip from "@sharedJS/tooltip";

const appendLandingPageLayer = function ($selectedLayer /* , $commonlayer */) {
	const _$selectedLayer = $(`#${$selectedLayer}-layer`);
	const $landingPageLayerWrapper = $("#LandingPageLayerWrapper");
	// if ($landingPageLayerWrapper.children().length > 0) {
	// 	$commonlayer.append($landingPageLayerWrapper.children());
	// 	_$selectedLayer.addClass("animated");
	// 	if (Modernizr.touchevents && Modernizr.mq(breakpoints['m_down'])) {
	// 		$(window).scrollTop(0);
	// 	}
	// } else {
	// 	_$selectedLayer.removeClass("animated");
	// 	const position = $commonlayer.attr("data-layerPos");
	// 	positionLandingPageLayer(position, $landingPageLayerWrapper);
	// }
	_$selectedLayer.removeClass("animated");
	positionLandingPageLayer();

	$landingPageLayerWrapper.append(_$selectedLayer);
	tooltip.init();
};

//place layer in landingpage(center,left,right)
const positionLandingPageLayer = function () {
	// die Position ist lt. Felix ausnahmslos links
	// const _position = position.toLowerCase();
	const _position = "left";
	const $oldLandingPageLayerWrapper = jQuery(".c-landingPage-login");
	$oldLandingPageLayerWrapper.wrap(jQuery('<div class="o-page-wrap u-p-none-m-down"></div>'));
	$oldLandingPageLayerWrapper.addClass(`c-landingPage-login--${_position}`);
};

export default appendLandingPageLayer;
