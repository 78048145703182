import { store } from "@/state/store";
import { pushEvent } from "@/services/dataLayerLogin";

export const switchLoginAndRegister = (buttonPosition: string) => {
	// Tracking (muss vor dem switch passieren, damit der Klick auf dem aktuellen Form getrackt wird
	let trackingAction = "SwitchForm";
	if (store.isModal) {
		trackingAction = store.isLogin ? `${buttonPosition}RegisterClick` : `${buttonPosition}LoginClick`;
	}
	pushEvent(trackingAction);
	// switch form
	store.isLogin = !store.isLogin;
};
