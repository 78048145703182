export const timeoutDuration = 30000;
export const pollingFrequency = 300;

export async function waitForEpoqInit(_timeoutDuration = timeoutDuration) {
	let isTimeout = false;
	const interval = setInterval(() => {
		isTimeout = true;
	}, _timeoutDuration);

	return new Promise(function check(resolve, reject) {
		if (window.EpoqNS) {
			clearInterval(interval);
			return resolve();
		}
		if (isTimeout) {
			clearInterval(interval);
			return reject();
		}
		setTimeout(() => check(resolve, reject), pollingFrequency);
	});
}

export async function setEpoqCartData() {
	const isShop = !window.ocm.isClassicSite;
	const recommendation = window.recommendationData.Recommendation;
	const isAuthenticated = recommendation.customerData.authlevel > 0;
	const pageType = recommendation.pageData.pageType;
	const pageUid = recommendation.pageData.pageUid;

	if (
		isShop &&
		isAuthenticated &&
		pageType !== "LandingPage" &&
		!["notFound", "multiStepCheckoutSummaryPage"].includes(pageUid)
	) {
		try {
			await waitForEpoqInit();

			const products =
				(pageType === "ThankYouPage"
					? recommendation.orderData.products
					: recommendation.customerData.cart?.products) ?? [];
			products.forEach((item, i) => {
				window.epoq_productIds[i] = String(item.style);
				window.epoq_unitPrices[i] = parseFloat(item.price);
				window.epoq_quantities[i] = Number(item.quantity);
			});
			pageType === "ThankYouPage" ? window.epoq_processCart() : window.epoq_updateCart();
		} catch (error) {
			console.error("error loading epoq / updateCart/processCart\n", error);
		}
	}
}

const EPOQ_PLP_TEST_GROUP_STORE = "plpTestGroup";
export function getEpoqTestGroup() {
	// z.B. "abtest_136_B"
	const epoqTestGroup = window.flowConfig?.partName ?? sessionStorage.getItem(EPOQ_PLP_TEST_GROUP_STORE);
	// we need to write the test group to session storage because `flowConfig` is not available in the checkout
	if (epoqTestGroup !== null) sessionStorage.setItem(EPOQ_PLP_TEST_GROUP_STORE, epoqTestGroup);

	return epoqTestGroup;
}
