import pushDataLayer from "@ocm/services/dataLayer.js";
import { store } from "@/state/store";

export function pushEvent(action: string, event_label = "") {
	const prefix = store.isClassic ? "C_" : "S_";
	const category = getEventCategory();

	const eventObj = {
		event: "GAevent",
		event_category: `${prefix}${category}`,
		event_action: `${prefix}${action}`,
		event_label: event_label ? `${prefix}${event_label}` : "",
	};

	pushDataLayer(eventObj);
}

// eslint-disable-next-line complexity
function getEventCategory() {
	const type = (store.isLogin && "Login") || "Registration";
	const form = (store.isModal && "Layer") || "Form";

	return (store.isReauth && "ReAuthPage") || (store.isLastStepPage && "RegisterConfirmation") || `${type}${form}`;
}
