import * as utils from "@sharedJS/utils";
import * as popUpOverlay from "@sharedJS/popUpOverlay";
import * as eightselect from "@shopJS/eightselect";
import * as storage from "@sharedJS/storage";
import * as tooltip from "@sharedJS/tooltip";

import * as classicCountdown from "@classicJS/classicCountdown";

import * as cart from "@shopJS/cart";
import * as formCaptcha from "@sharedJS/formCaptcha";
import * as banderole from "@sharedJS/banderole";
import * as emarsys from "@shopJS/emarsys";
import * as login from "@sharedJS/login";

window.ocm = window.ocm || {};

window.ocm.utils = utils || {};
window.ocm.popUpOverlay = popUpOverlay || {};
window.ocm.eightselect = eightselect || {};
window.ocm.storage = storage || {};
window.ocm.tooltip = tooltip || {};

window.ocm.classicCountdown = classicCountdown || {};

window.ocm.cart = cart || {};
window.ocm.formCaptcha = formCaptcha || {};

window.ocm.banderole = banderole || {};
window.ocm.emarsys = emarsys || {};
window.ocm.login = login || {};

// Abhängigkeiten Tag-Manager: https://redbox.outletcity.com/jira/browse/TUB-7473
// Abhängigkeiten hybris CMS: https://redbox.outletcity.com/jira/browse/TUB-7472
