let $couponTitle;

function init() {
	couponTriggerMobile();
}

function couponTriggerMobile() {
	$couponTitle = $(".js-coupon-trigger");
	$couponTitle.on("click", function () {
		if ($(this).hasClass("is-open")) {
			$(this).removeClass("is-open").show("slow");
		} else {
			$(this).addClass("is-open").show("slow");
		}
	});
}

export { init, couponTriggerMobile };
