import OcmMessenger from "ocm_mercurius_messenger";
import { messengerPublicationTypes } from "@ocm/services/services.constants.js";

const requestLogin = function () {
	OcmMessenger.publish(messengerPublicationTypes.REQUEST_LOGIN, {
		isClosable: true,
	});
};

export { requestLogin };
