import { fetchWithCSRFToken } from "@/services/rest";
import { store } from "@/state/store";
import { pushEvent } from "./dataLayerLogin";
import { $t } from "@/main";

export type LoginAction = "login" | "registration" | "reauth" | "apple" | "facebook" | "google";

const contextPath: string = window.ocm.config.encodedContextPath;
const validateUrls = {
	facebook: `${contextPath}/rest/auth/facebook/`,
	apple: `${contextPath}/rest/auth/apple/`,
	google: `${contextPath}/rest/auth/google/`,
	login: `${contextPath}/rest/j_spring_security_check/`,
	reauth: `${contextPath}/rest/j_spring_security_check/`,
	registration: `${contextPath}/rest/register`,
};

export async function authenticate(
	validationCredentials: Record<string, unknown>,
	action: LoginAction,
	csrfTokenAttempts = 0
): Promise<boolean> {
	resetErrors();

	//+++++++++++++++++++++++++++++++++++++++++++++++++++++++++
	// collect and transform Request Data

	const validateUrl = validateUrls[action];
	const validationData = {
		rememberMe: "true",
		...validationCredentials,
	};
	const bodyData = new URLSearchParams(validationData).toString();
	const fetchOptions = {
		method: "post",
		headers: {
			"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
		},
		body: bodyData,
	};

	//+++++++++++++++++++++++++++++++++++++++++++++++++++++++++
	// make Request
	const responseData: ResponseData = await fetchWithCSRFToken(validateUrl, fetchOptions);
	const { httpStatus, globalErrorMessages, fieldErrors, redirectUrl } = responseData;

	if (httpStatus === 200) {
		_handleOK(action, redirectUrl);
		return true;
	} else if (httpStatus === 412) {
		// _handle412 calls authenticate recursively and returns success as a Promise (true / false)
		return _handle412(action, validationCredentials, csrfTokenAttempts);
	} else if (httpStatus === 503) {
		handleNetworError();
	} else {
		const errorData: ErrorData = {
			errorMessages: globalErrorMessages || [],
			fieldErrors: fieldErrors || [],
			httpStatus,
		};
		handleError(action, errorData);
	}

	return false;
}

function _handleOK(action: LoginAction, redirectUrl: string | undefined) {
	// fix für TUB-15305 und TUB-15607 iOS Problem bei PDP oder PLS
	// ein einfaches Reload, wenn
	// 1) wenn es Login über das Formular ist
	// 2) wenn es die LogoutPage ist
	if ((action === "login" && !document.body.classList.contains("page-LogoutPage")) || !redirectUrl) {
		location.reload();
	} else {
		// todo: assign vs replace ?? replace bei Social Login ???
		location.assign(redirectUrl);
	}
	if (action === "login" || action === "reauth") localStorage.setItem("loginTabActive", "true");
}

function _handle412(action: LoginAction, validationCredentials: Record<string, unknown>, csrfTokenAttempts: number) {
	if (csrfTokenAttempts++ < 3) {
		// call authenticate recursively and return success as a Promise (true / false)
		return authenticate(validationCredentials, action, csrfTokenAttempts);
	}
	store.httpErrorStatusCode = 412;
	store.isSocialLogin = isSocialLogin(action);
	store.globalErrorMessages = [$t("errorMessages.httpErrorStatusCode412")];

	// ERROR TRACKING
	handleErrorTracking(action, { errorMessages: [], fieldErrors: [], httpStatus: 412 });
	return false;
}

function handleNetworError() {
	pushEvent("backend_error", "invalid_authentication_response");
	store.globalErrorMessages = [$t("errorMessages.httpError")];
}

function handleError(action: LoginAction, errorData: ErrorData) {
	store.globalErrorMessages = errorData.errorMessages;
	store.httpErrorStatusCode = errorData.httpStatus;
	store.isSocialLogin = isSocialLogin(action);

	// EROOR TRACKING
	handleErrorTracking(action, errorData);
}

function handleErrorTracking(action: LoginAction, errorData: ErrorData) {
	let eventLabel;

	if (isSocialLogin(action) && errorData.errorMessages.length > 0) {
		eventLabel = `invalid_${action}_token`;
	} else if (["login", "reauth"].includes(action) && errorData.fieldErrors.length) {
		eventLabel = "invalid_credentials";
	} else if (
		action === "registration" &&
		errorData.fieldErrors.some((field: FieldError) => field.fieldName === "j_username")
	) {
		eventLabel = "submit_email_error";
	} else {
		eventLabel = `${action}_error_status_${errorData.httpStatus}`;
	}

	pushEvent("backend_error", eventLabel);
}

function isSocialLogin(action: LoginAction) {
	return action === "apple" || action === "facebook" || action === "google";
}

function resetErrors() {
	store.httpErrorStatusCode = null;
	store.globalErrorMessages = [];
	store.isSocialLogin = false;
}
